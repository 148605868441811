import {
    UPDATE_CONFIG as SOURCE_UPDATE_CONFIG,
    updateConfig as sourceUpdateConfig,
    updateConfigDevice as sourceUpdateConfigDevice,
    updateCurrentCurrency as sourceUpdateCurrentCurrency,
} from 'SourceStore/Config/Config.action';

import { ConfigActionType } from './Config.type';

// TODO: implement updateCurrentCurrency
export const updateCurrentCurrency = sourceUpdateCurrentCurrency;

// TODO: implement UPDATE_CONFIG
export const UPDATE_CONFIG = SOURCE_UPDATE_CONFIG;

// TODO: implement updateConfig
export const updateConfig = sourceUpdateConfig;

// TODO: implement updateConfigDevice
export const updateConfigDevice = sourceUpdateConfigDevice;

/** @namespace Zuporder/Store/Config/Action/saveCurrentPage */
export const saveCurrentPage = (pathname: string) => ({
    type: ConfigActionType.SAVE_CURRENT_PAGE,
    pathname,
});
